import React, { useLayoutEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import CookiesPolicyContent from "../components/sections/CookiesPolicyContent/CookiesPolicyContent";
import { Helmet } from "react-helmet"


const CookiesPolicy = () => {
  useLayoutEffect(() => {
    setTimeout(() => {
      document.querySelector("body").style.backgroundColor = "var(--white)";
    }, 1000);
  }, []);
  return (
    <>
    <Helmet>
          <title>Cookies policy</title>
          <meta name="description" content="endshift is a solution of the last resort. It helps enterprises, small to large businesses, and startups to scale and remove any technological congestion." />
          <link rel="canonical" href="https://endshift.io/" />
      </Helmet>
      <Header dataColor="header-dark" />
      <CookiesPolicyContent />
      <Footer />
    </>
  );
};

export default CookiesPolicy;

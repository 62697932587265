import React from "react";
import * as CookiesPolicyStyles from "./CookiesPolicyContent.module.scss";

const CookiesPolicyContent = () => {
  return (
    <>
      <main className={CookiesPolicyStyles.CookiesPolicyWrapper}>
        <h1 className="h1">Cookies Policy</h1>
        <section>
          <p className="p large-para text-uppercase es-mb-utility-48-24">
            PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND END-SHIFT'S
            POLICIES <br></br> AND PRACTICES REGARDING YOUR INFORMATION AND HOW
            END-SHIFT WILL TREAT IT.
          </p>
          <p className="p">
            Please read this policy carefully to understand end-shift's policies
            and practices regarding your information and how end-shift will
            treat it. This policy may change from time to time. Your continued
            use of end-shift's proprietary online content, functionality and
            services offered on or via the website ("Service") after changes are
            made is deemed to be acceptance of those changes, therefore, you
            should check the policy periodically for updates.
          </p>
          <p className="p">
            end-shift, LLC, ("end-shift"). This policy describes the types of
            information end-shift may collect from you or that you may provide
            when you visit end-shift or its related website, and/or use various
            aspects of end-shift and its practices for collecting, using,
            maintaining, protecting, and disclosing that information.
          </p>

          <h3 className="h3 es-mb-utility-24-16">
            Information end-shift Collects About You and How end-shift Collects
            It
          </h3>
          <ul className="ul es-mb-utility-32-24">
            <li>
              by which you may be personally identified, such as first and last
              name, name of business, postal address, e-mail address, telephone
              number, or and any other identifier by which you may be contacted
              online or offline ("personal information");
            </li>
            <li>
              that is about you but individually does not identify you; and/or
              about your internet connection, the equipment you use to access
              www.end-shift.com and usage details.
            </li>
          </ul>

          <h6 className="h6">
            Information end-shift Collect Through Automatic Data Collection
            Technologies.
          </h6>
        </section>
      </main>
    </>
  );
};

export default CookiesPolicyContent;
